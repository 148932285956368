<template>
  <v-container fluid class="contract-terms-details">
    <v-row dense>
      <v-col>
        <v-select
          v-model="contractTerms.contractType"
          dense
          outlined
          data-test-id="contractContractType"
          label="Contract Type*"
          hide-details="auto"
          class="contract-type"
          :disabled="disabled || !isLatest || !isCreate"
          :rules="[ruleSet.required]"
          :items="contractTypes"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="contractTerms.locale"
          dense
          outlined
          label="Locale*"
          data-test-id="contractLocale"
          item-text="text"
          item-value="value"
          :disabled="disabled || !isCreate || !isLatest"
          :items="supportedLocales"
          :rules="[ruleSet.required]"
        />
      </v-col>
      <v-col v-if="isCreate" cols="2">
        <v-checkbox
          dense
          label="Optional"
          class="mt-1"
          data-test-id="contractTriggerOptional"
          hide-details="auto"
          :input-value="triggerOptional"
          :disabled="disabled"
          @change="triggerOptional = !triggerOptional"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="contractTerms.validFrom"
          dense
          outlined
          label="Valid from"
          data-test-id="contractValidFrom"
          hide-details="auto"
          type="date"
          :disabled="disabled || !isLatest"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="contractTerms.validUntil"
          dense
          outlined
          label="Valid until"
          data-test-id="contractValidUntil"
          hide-details="auto"
          type="date"
          :disabled="disabled || !isLatest"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-subheader class="pl-0">Content</v-subheader>
        <textarea
          ref="editor"
          placeholder="Add content..."
          data-test-id="contractTermContentInput"
        />
        <v-subheader class="pl-0">Preview</v-subheader>
        <div
          v-html="sanitize(content)"
          class="content-preview"
          data-test-id="contractTermContentPreview"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validationMixin from "mixins/field-rule-validation";
import contractServiceMixin from "mixins/contract-service-mixin";

import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/addon/display/placeholder.js";
export default {
  mixins: [validationMixin, contractServiceMixin],
  components: {},
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    isLatest: {
      type: Boolean,
      required: true,
    },

    isCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      contractTerms: this.$cloneObject(this.value),
      triggerOptional: false,
      content: null,
      editor: null,
    };
  },

  mounted() {
    if (this.isCreate) {
      this.$set(this.contractTerms, "optional", this.triggerOptional);
    }

    this.content = this.contractTerms?.content ?? "";

    this.$nextTick(() => {
      //create a new editor instance and set options
      this.editor = CodeMirror.fromTextArea(this.$refs.editor, {
        height: "auto",
        theme: "xq-light",
        mode: {
          name: "xml",
          html: true,
          statementIndent: 2,
        },
      });
      this.editor.on("keyup", (editor) => (this.content = editor.getValue()));
      this.editor.setValue(this.content);
      this.editor.setSize("100%", 200);
      //Focus the text area
      this.editor.focus();
    });
  },

  watch: {
    contractTerms: {
      handler: function (contractTerms) {
        if (this.isLatest || this.isCreate) {
          this.$emit("input", contractTerms);
        }
      },
      deep: true,
    },

    triggerOptional(isOptional) {
      this.$set(this.contractTerms, "optional", isOptional);
    },

    disabled(isDisabled) {
      if (!this.editor) return;
      this.editor.setOption("readOnly", isDisabled ? "nocursor" : false);
    },

    content(content) {
      this.$set(this.contractTerms, "content", content);
    },
  },

  methods: {
    sanitize(content) {
      return this.$sanitize(content);
    },
  },

  computed: {
    supportedLocales() {
      if (!this.isCreate) {
        return [this.contractTerms.locale];
      }
      return this.$store.state.localization.supportedLocales;
    },

    customToolbar() {
      return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ];
    },
  },
};
</script>

<style scoped>
.contract-terms-details .contract-terms-content-editor.disabled {
  opacity: 0.38;
}

.contract-terms-details .contract-key::v-deep .v-text-field__prefix {
  padding-right: 0;
  padding-top: 2px;
}

.contract-terms-details::v-deep .CodeMirror {
  border: 1px solid rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  padding: 4px;
}

.contract-terms-details::v-deep .CodeMirror pre.CodeMirror-placeholder {
  color: #999;
}

/* Remove all external styling in content preview to show the real styles */
.contract-terms-details .content-preview {
  all: initial !important;
  padding-left: 4px;
}

.contract-terms-details .content-preview::v-deep button,
.contract-terms-details .content-preview::v-deep input,
.contract-terms-details .content-preview::v-deep select,
.contract-terms-details .content-preview::v-deep textarea {
  all: revert;
}
</style>