<template>
  <DetailView
    data-test-id="contractDetail"
    :loading="runningAction"
    :title="title"
    @close="$emit('close')"
  >
    <template #actions="{ loading }">
      <v-btn
        v-if="isCreate"
        class="contract-terms-create-btn"
        data-test-id="contractTermsCreateBtn"
        :color="hasViolation ? 'error' : 'green'"
        :outlined="!isCreate && !hasChanged"
        :disabled="loading || !latestVersionShown"
        @click="upsertContractTerms"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          color="white"
          data-test-id="contractTermsViolationAlert"
          alignment="left"
        />
        Create
      </v-btn>
    </template>
    <template #content="{ loading }">
      <v-form
        @submit.prevent
        ref="contractForm"
        class="contract-form"
        data-test-id="contractForm"
      >
        <v-card flat class="d-flex flex-column">
          <v-card-title>
            <div>Terms</div>
            <v-divider vertical class="mx-3" />
            <v-spacer />
            <div class="d-flex">
              <v-select
                v-if="!isCreate"
                v-model="displayedVersion"
                outlined
                dense
                hide-details
                class="contract-term-version-select"
                label="Displayed Version"
                :items="versionNumbers"
              >
                <template #append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div class="help-tooltip">
                      Displays all currently active versions sorted by version
                      number
                    </div>
                  </v-tooltip>
                </template>
              </v-select>
              <v-divider vertical class="mx-3" />
              <v-btn
                v-if="!isCreate"
                class="contract-terms-save-btn ml-3"
                data-test-id="contractTermsSaveBtn"
                :color="hasViolation ? 'error' : 'green'"
                :outlined="!isCreate && !hasChanged"
                :disabled="loading || !latestVersionShown"
                @click="upsertContractTerms"
              >
                <ViolationAlert
                  v-if="hasViolation"
                  :violation="violation"
                  color="white"
                  data-test-id="contractTermsViolationAlert"
                  alignment="left"
                />
                Create version
              </v-btn>
            </div>
          </v-card-title>

          <ContractTermsDetails
            v-if="displayedVersion || isCreate"
            :value="versions[displayedVersion]"
            :key="displayedVersion"
            :disabled="loading"
            :is-create="isCreate"
            :is-latest="latestVersionShown"
            @input="updateContractTerms"
          />
        </v-card>

        <ContractTriggerOverview
          v-if="!isCreate"
          :contract-type="contract.contractType"
        />
      </v-form>
    </template>
  </DetailView>
</template>

<script>
import DetailView from "components/common/templates/DetailView";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import ContractTermsDetails from "./terms/ContractTermsDetails.vue";
import contractServiceMixin from "mixins/contract-service-mixin";
import ContractTriggerOverview from "./trigger/ContractTriggerOverview.vue";

export default {
  mixins: [contractServiceMixin],
  components: {
    DetailView,
    ContractTermsDetails,
    ViolationAlert,
    ContractTriggerOverview,
  },

  props: {
    previewData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    previousVersions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    contracts: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      contract: this.$cloneObject(this.previewData),
      runningAction: false,
      displayedVersion: null,
      violation: null,
      hasChanged: false,
    };
  },

  mounted() {
    if (!this.isCreate) {
      this.savedContract = this.$cloneObject(this.contract);
    } else {
      this.$set(this.contract, "termsVersion", 1);
      this.$set(this.contract, "content", "");
    }
    this.displayedVersion = this.latestVersion;
  },

  watch: {
    contract: {
      handler: function (contract) {
        this.hasChanged = !this.$isEqual(contract, this.savedContract);
      },
      deep: true,
    },

    savedContract: {
      handler: function (savedContract) {
        this.hasChanged = !this.$isEqual(this.contract, savedContract);
      },
      deep: true,
    },

    hasChanged(hasChanged) {
      this.$set(this.$route.params, "unsavedChanges", hasChanged);
    },
  },

  methods: {
    updateContractTerms(contractTerms) {
      this.contract = Object.assign({}, contractTerms);
    },

    async upsertContractTerms() {
      if (!this.isCreate) {
        const confirmed = await this.$confirm(
          "Create new terms version?",
          "Are you sure you want to create a new version of contract terms for <strong>" +
            this.contract.contractType +
            "</strong> ?"
        );
        if (!confirmed) return;
      } else {
        const contractId = this.getContractId(this.contract);
        const existing = this.existingContractIds.some(
          (id) => id === contractId
        );
        if (existing) {
          //If there are already existing terms for the given contract key and locale
          //ask the user if he wants to create a new version
          const confirmed = await this.$confirm(
            "Create new terms version?",
            `There are already existing contract terms for contract <strong>` +
              this.contract.contractKey +
              `
          </strong> with locale <strong>` +
              this.contract.locale +
              `</strong>. Do you want to create a new version?`
          );
          if (!confirmed) return;
        }
      }

      if (!this.validate()) return;
      try {
        this.runningAction = true;
        const contractType = this.contract.contractType;
        const locale = this.contract.locale;
        const upsertContract = {
          contractType,
          validFrom: this.contract.validFrom,
          validUntil: this.contract.validUntil,
          content: {
            [locale]: this.contract.content,
          },
        };

        const successMsg = this.isCreate
          ? "Contract terms of type <strong>" +
            contractType +
            "</strong> created"
          : "New version of contract terms for type <strong>" +
            contractType +
            "</strong> created";

        const res =
          await this.$store.$coreApi.coreContractApi.upsertContractTerms(
            this.selectedDomain,
            upsertContract,
            { successMsg }
          );

        if (!res?.ok) {
          const violation = await res.json();
          this.violation = violation;
          this.runningAction = false;
          return;
        }

        if (this.isCreate) {
          //Create corresponding contract trigger to contract terms
          const existingTriggers = await this.loadContractTriggersForContract(
            contractType,
            true
          );
          if (!existingTriggers || existingTriggers?.length === 0) {
            const contractTrigger = {
              contractType,
              triggerOptions: {
                optional: !!this.contract.optional,
                recommended: false,
                interaction: "INFO",
              },
            };
            await this.upsertContractTrigger(contractTrigger, true, true);
          }
          this.$delete(this.contract, "optional");
        }

        this.savedContract = this.$cloneObject(this.contract);
        this.hasChanged = false;
        await this.$nextTick();
        const id = this.isCreate
          ? this.getContractId(this.contract)
          : this.contract?.id;
        this.$emit("reload", id);
      } catch (e) {
        console.error(e);
        this.runningAction = false;
      }
    },

    validate() {
      const form = this.$refs.contractForm;
      if (!this.$validateVForm(form)) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return false;
      }
      this.violation = null;
      return true;
    },
  },

  computed: {
    isCreate() {
      return !this.previewData.contractKey;
    },

    title() {
      if (this.isCreate) {
        return "New contract terms";
      }
      const type = this.previewData.contractType.toUpperCase();
      return type + " (" + this.previewData.locale + ")";
    },

    versions() {
      const versions = (this.previousVersions ?? []).concat([this.contract]);
      return versions.reduce((obj, version) => {
        this.$set(obj, version.termsVersion, version);
        return obj;
      }, {});
    },

    versionNumbers() {
      const versionNumbers = Object.keys(this.versions)
        .sort((nr1, nr2) => nr2 - nr1)
        .map((number) => {
          const value = Number(number);
          const text =
            number + (value === this.latestVersion ? " (latest)" : "");
          return {
            text,
            value,
          };
        });
      return versionNumbers;
    },

    latestVersion() {
      return this.contract.termsVersion;
    },

    latestVersionShown() {
      return this.isCreate || this.displayedVersion === this.latestVersion;
    },

    hasViolation() {
      return !!this.violation;
    },

    existingContractIds() {
      return this.contracts.reduce((ids, contract) => {
        const id = this.getContractId(contract);
        if (!ids.includes(id)) {
          ids.push(id);
        }
        return ids;
      }, []);
    },
  },
};
</script>

<style scoped>
.contract-form .contract-term-version-select {
  max-width: 200px;
}
</style>