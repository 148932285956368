export default {
    methods: {
        getContractId({ contractType, locale }) {
            return contractType + ":" + locale;
        },

        async upsertContractTrigger(contractTrigger, isNew = false, returnErrors = false) {
            try {
                const successMsg =
                    (isNew ? "Created" : "Updated") +
                    " contract trigger for contract type <strong>" +
                    contractTrigger.contractType +
                    "</strong>";

                if (isNew) {
                    const response =
                        await this.$store.$coreApi.coreContractApi.createContractTrigger(
                            this.selectedDomain,
                            contractTrigger,
                            {
                                successMsg,
                                returnErrors
                            }
                        );
                    return response
                } else {
                    const response =
                        await this.$store.$coreApi.coreContractApi.updateContractTrigger(
                            this.selectedDomain,
                            contractTrigger,
                            {
                                successMsg,
                                returnErrors
                            }
                        );
                    return response;
                }
            } catch (e) {
                console.error(e);
                return null;
            }
        },

        async loadContractTriggersForContract(contractType, returnErrors = false) {
            const body = { contractType };
            const res =
                await this.$store.$coreApi.coreContractApi.getContractTriggers(
                    this.selectedDomain,
                    body,
                    {
                        suppressedErrorCodes: [404],
                        returnErrors
                    }
                );

            if (!res?.ok) return [];
            const response = await res.json();
            return response?.contractTriggers ?? [];
        }
    },

    computed: {

        selectedDomain() {
            return this.$store.state.selectedDomain;
        },

        contractKeyPrefix() {
            const installationName = this.$configuration.installationName;
            return "alturos.ps." + installationName + "." + this.selectedDomain + ".";
        },

        contractTypes() {
            return [
                { text: "PRIVACY", value: "privacy" },
                { text: "MARKETING", value: "marketing" },
                { text: "LEGAL", value: "legal" },
                { text: "GENERAL", value: "general" },
            ];
        },
    }
}