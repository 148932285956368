var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DetailView',{attrs:{"data-test-id":"contractDetail","loading":_vm.runningAction,"title":_vm.title},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var loading = ref.loading;
return [(_vm.isCreate)?_c('v-btn',{staticClass:"contract-terms-create-btn",attrs:{"data-test-id":"contractTermsCreateBtn","color":_vm.hasViolation ? 'error' : 'green',"outlined":!_vm.isCreate && !_vm.hasChanged,"disabled":loading || !_vm.latestVersionShown},on:{"click":_vm.upsertContractTerms}},[(_vm.hasViolation)?_c('ViolationAlert',{attrs:{"violation":_vm.violation,"color":"white","data-test-id":"contractTermsViolationAlert","alignment":"left"}}):_vm._e(),_vm._v(" Create ")],1):_vm._e()]}},{key:"content",fn:function(ref){
var loading = ref.loading;
return [_c('v-form',{ref:"contractForm",staticClass:"contract-form",attrs:{"data-test-id":"contractForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"flat":""}},[_c('v-card-title',[_c('div',[_vm._v("Terms")]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[(!_vm.isCreate)?_c('v-select',{staticClass:"contract-term-version-select",attrs:{"outlined":"","dense":"","hide-details":"","label":"Displayed Version","items":_vm.versionNumbers},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('div',{staticClass:"help-tooltip"},[_vm._v(" Displays all currently active versions sorted by version number ")])])]},proxy:true}],null,true),model:{value:(_vm.displayedVersion),callback:function ($$v) {_vm.displayedVersion=$$v},expression:"displayedVersion"}}):_vm._e(),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),(!_vm.isCreate)?_c('v-btn',{staticClass:"contract-terms-save-btn ml-3",attrs:{"data-test-id":"contractTermsSaveBtn","color":_vm.hasViolation ? 'error' : 'green',"outlined":!_vm.isCreate && !_vm.hasChanged,"disabled":loading || !_vm.latestVersionShown},on:{"click":_vm.upsertContractTerms}},[(_vm.hasViolation)?_c('ViolationAlert',{attrs:{"violation":_vm.violation,"color":"white","data-test-id":"contractTermsViolationAlert","alignment":"left"}}):_vm._e(),_vm._v(" Create version ")],1):_vm._e()],1)],1),(_vm.displayedVersion || _vm.isCreate)?_c('ContractTermsDetails',{key:_vm.displayedVersion,attrs:{"value":_vm.versions[_vm.displayedVersion],"disabled":loading,"is-create":_vm.isCreate,"is-latest":_vm.latestVersionShown},on:{"input":_vm.updateContractTerms}}):_vm._e()],1),(!_vm.isCreate)?_c('ContractTriggerOverview',{attrs:{"contract-type":_vm.contract.contractType}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }