<template>
  <div class="contract-trigger-overview">
    <v-card flat class="d-flex flex-column flex-grow-1">
      <v-card-title>
        <div>Triggers</div>
        <v-divider vertical class="mx-3" />
        <v-spacer></v-spacer>
        <v-btn
          v-if="contractTriggers.length === 0"
          color="primary"
          data-test-id="addContractTriggerBtn"
          :disabled="disabled || runningAction"
          @click="addContractTrigger"
        >
          Add trigger
        </v-btn>
      </v-card-title>
      <v-data-table
        sort-by="contractTriggerKey"
        sort-desc
        hide-default-footer
        class="contract-triggers-table"
        :loading="runningAction"
        :items="contractTriggers"
        :headers="contractTriggerHeaders"
        :items-per-page="-1"
      >
        <!-- eslint-disable-next-line -->
        <template #item.triggerOptions.optional="{ item }">
          <v-simple-checkbox
            v-ripple="false"
            color="primary"
            :value="item.triggerOptions.optional"
            :data-test-id="
              'contractTriggerOptionalCheckbox_' + item.contractTriggerKey
            "
            :disabled="runningAction || disabled"
            @input="updateOptional(item)"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import contractServiceMixin from "mixins/contract-service-mixin";
export default {
  mixins: [contractServiceMixin],
  props: {
    contractType: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      contractTriggers: [],
      runningAction: false,
    };
  },

  mounted() {
    this.loadContractTriggers();
  },

  methods: {
    async loadContractTriggers() {
      try {
        this.runningAction = true;
        this.contractTriggers = await this.loadContractTriggersForContract(
          this.contractType
        );
      } finally {
        this.runningAction = false;
      }
    },

    async addContractTrigger() {
      try {
        this.runningAction = true;
        const contractTrigger = {
          contractType: this.contractType,
          triggerOptions: {
            optional: false,
            recommended: false,
            interaction: "INFO",
          },
        };

        await this.upsertContractTrigger(contractTrigger, true);
        await this.loadContractTriggers();
      } finally {
        this.runningAction = false;
      }
    },

    async updateOptional({ triggerOptions }) {
      try {
        this.runningAction = true;
        const contractTrigger = {
          contractType: this.contractType,
          triggerOptions: {
            ...triggerOptions,
            optional: !triggerOptions.optional,
          },
        };

        await this.upsertContractTrigger(contractTrigger);
        await this.loadContractTriggers();
      } finally {
        this.runningAction = false;
      }
    },

    async deleteContractTrigger({ contractTriggerKey, contractType, isNew }) {
      try {
        this.runningAction = true;
        if (isNew) {
          this.contractTriggers = [];
        } else {
          const confirmed = await this.$confirm(
            "Delete contract trigger?",
            `Are you sure you want to delete the contract trigger <strong>` +
              contractTriggerKey +
              `</strong> for contract <strong>` +
              contractType +
              `</strong>? 
              <br>This trigger is shared by all contracts with the same key!`
          );
          if (!confirmed) return;

          const successMsg =
            "Delete contract trigger <strong>" +
            contractTriggerKey +
            "<strong>";

          const response =
            await this.$store.$coreApi.coreContractApi.deleteContractTrigger(
              this.selectedDomain,
              contractTriggerKey,
              {
                successMsg,
              }
            );
          if (!response?.ok) return;
          await this.loadContractTriggers();
        }
      } finally {
        this.runningAction = false;
      }
    },

    updateTriggerOptions(item, property, value) {
      this.$set(item.triggerOptions, property, value);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    contractTriggerHeaders() {
      return [
        { text: "Trigger Key", value: "contractTriggerKey" },
        { text: "Contract Type", value: "contractType" },
        {
          text: "Optional",
          value: "triggerOptions.optional",
          sortable: false,
          width: "64px",
        },
        { text: "", value: "actions", sortable: false, width: "128px" },
      ];
    },
  },
};
</script>

<style scoped>
.contract-trigger-overview {
  display: flex;
  flex-grow: 1;
  padding: 12px;
}

.contract-trigger-overview > .contract-triggers-table {
  flex-grow: 1;
}
</style>